import React, { useState } from 'react';
import microphoneSVG from '../../static/microphone.svg';
import microphoneActiveSVG from '../../static/microphone-active.svg';
import './App.css';
import { AddBook } from '../../repository/book';
import { auth } from '../../config/firebaseConfig';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from 'firebase/auth';

var stream: MediaStream, recorder: MediaRecorder
var chunks: Blob[] = []

function App() {
  // AddBook({
  //   name: 'Hello World',
  //   year: 2000
  // })
  // use state to show or not the audio

  const [src, setSrc] = useState<string>()
  const [listening, setListening] = useState<boolean>(false)

  let waitChunck = () => {
    return new Promise<void>(resolve => {
      const checkChunks = () => {
        console.log("checkChunks")
        if (chunks.length > 0) {
          resolve()
        } else {
          setTimeout(checkChunks, 1000)
        }
      }
      checkChunks()
    })
  }


  var stop = async () => {
    recorder.stop()
    stream.getTracks().forEach((track) => {
      if (track.readyState === 'live') {
        track.stop();
      }
    });
    await waitChunck()
    console.log(chunks)

    let data = await chunks[0].arrayBuffer()
    let dataArray = Array.from(new Uint8Array(data))
    // Uncaught (in promise) RangeError: Maximum call stack size exceeded
    const base64String = btoa(
      String.fromCharCode.apply(null, dataArray)
    );
    console.log(base64String)
    const helloWorld = httpsCallable(functions, 'helloWorld');
    helloWorld({ file_base64: base64String })
      .then((result) => {
        const data = result.data;
        console.log(data)
      }).catch(e => console.log(e));
    const url = URL.createObjectURL(chunks[0])
    setSrc(url)
  }

  var start = async () => {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    recorder = new MediaRecorder(stream);
    recorder.ondataavailable = e => {
      chunks.push(e.data);
    }
    recorder.start();
  }

  const [loggedUser, setUser] = useState<User | null>(null)
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // console.log(user)
      setUser(user)
    } else {
      setUser(null)
    }
  });


  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
  }
  const SignOut = () => {
    signOut(auth)
  }

  const Listen = async (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault()
    console.log("clicked")
    // console.log(listening)
    if (listening) {
      await stop()
      //if you move up everything breaks :D
      setListening(false)
    } else {
      chunks = []
      await start()
      //if you move up everything breaks :D
      setListening(true)
    }
  }

  const imgSrc = listening ? microphoneActiveSVG : microphoneSVG


  const functions = getFunctions(undefined, "southamerica-east1")
  const isLocalENV = process.env.NODE_ENV
  console.log("isLocalENV", isLocalENV)
  if (isLocalENV == "development") {
    connectFunctionsEmulator(functions, "127.0.0.1", 5001)
  }


  return (
    <div className="App">
      <header className="App-header">
        <h1>listening: {listening ? "true" : "false"}</h1>
        <img src={imgSrc} id="mic" alt="microphone icon" onClick={Listen} />
        <button onClick={googleSignIn}>Google</button>
        <button onClick={SignOut}>SignOut</button>
        <audio controls={true} src={src} autoPlay={false} />
        <h1>{loggedUser?.displayName}</h1>
      </header>
    </div>
  );
}

export default App;
